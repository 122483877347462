import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../components/Shared/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { createPost, updatePost } from "../../actions/posts";
import FileBase from "react-file-base64";

const AddPost = ({ currentId, setCurrentId }) => {
  const [postData, setPostData] = useState({
    title: "",
    content: "",
    author: "",
    selectedFile: "",
  });
  const post = useSelector((state) =>
    currentId ? state.posts.find((message) => message._id === currentId) : null
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (post) setPostData(post);
  }, [post]);
  const clear = () => {
    setCurrentId(0);
    setPostData({
      title: "",
      content: "",
      author: "",
      selectedFile: "",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentId === 0) {
      dispatch(createPost(postData));
      clear();
    } else {
      dispatch(updatePost(currentId, postData));
      clear();
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Box
          component={"form"}
          onSubmit={handleSubmit}
          method="POST"
          encType="multipart/form-data"
        >
          <Paper sx={{ paddingY: 5 }}>
            <Grid
              container
              display={"flex"}
              spacing={2}
              justifyContent={"center"}
            >
              <Grid item xl={8}>
                <Box>
                  <h1>Add a new post</h1>
                </Box>
                <Box display={"flex"} justifyContent={"center"}>
                  <Paper>
                    <Grid container spacing={2}>
                      <Grid item xl={12}>
                        <Box textAlign={"left"}>
                          <label>Title</label>
                        </Box>
                        <Input
                          value={postData.title}
                          type="text"
                          placeholder="Type Post Title..."
                          onChange={(e) =>
                            setPostData({ ...postData, title: e.target.value })
                          }
                          name="title"
                        />
                      </Grid>
                      <Grid item xl={12}>
                        <Box textAlign={"left"}>
                          <label>Excerpt</label>
                        </Box>
                        <Input placeholder="Add a summary of the post to appear on your home page or blog...." />
                      </Grid>
                      <Grid item xl={12}>
                        <Box textAlign={"left"}>
                          <label>Content</label>
                        </Box>
                        <TextField
                          fullWidth
                          multiline
                          rows={10}
                          placeholder="Type post content here...."
                          value={postData.content}
                          onChange={(e) =>
                            setPostData({
                              ...postData,
                              content: e.target.value,
                            })
                          }
                          name="content"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
              </Grid>

              <Grid item xl={4} padding={5}>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Box>
                    <Paper
                      sx={{
                        padding: 2,
                        borderRadius: "20px",
                        border: "solid 1px goldenrod",
                      }}
                    >
                      <Box textAlign={"left"}>
                        <label>Author</label>
                      </Box>
                      <TextField
                        fullWidth
                        placeholder="Type author name here...."
                        value={postData.author}
                        onChange={(e) =>
                          setPostData({ ...postData, author: e.target.value })
                        }
                        name="author"
                      />
                    </Paper>
                  </Box>
                  <Box>
                    <Paper
                      sx={{
                        padding: 2,
                        borderRadius: "20px",
                        border: "solid 1px goldenrod",
                      }}
                    >
                      <Box textAlign={"left"}>
                        <label>Cover</label>
                      </Box>
                      <FileBase
                        type="file"
                        multiple={false}
                        onDone={({ base64 }) =>
                          setPostData({ ...postData, selectedFile: base64 })
                        }
                      />
                    </Paper>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  gap={2}
                  paddingTop={2}
                  justifyContent={"flex-end"}
                >
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "darkred" }}
                  >
                    Delete blog post
                  </Button>
                  <Button
                    type="submit"
                    sx={{ backgroundColor: "darkgreen" }}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default AddPost;
