import React, { useState } from "react";
import { Container, Typography, Button, Box, Paper } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

import logo from "../../assets/logo-coffee.png";
import Input from "../../components/Shared/Input/Input";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SharedBTN from "../../components/Shared/SharedBTN/SharedBTN";
import toast from "react-hot-toast";
import { useAuth } from "../../context/auth";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .min(6, "Password must be at least 8 characters")
    .required("Required"),
});

const Login = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // In your logout function
  Cookies.remove("token");
  return (
    <>
      <Box
        sx={{
          height: {
            xl: "100vh",
            lg: "100vh",
            md: "85vh",
            sm: "20vh",
            xs: "20vh",
          },
        }}
      >
        <Container maxWidth="sm">
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            paddingY={10}
            sx={{
              justifyContent: {
                xl: "flex-end",
                lg: "flex-end",
                md: "flex-end",
                sm: "center",
                xs: "center",
              },
            }}
          >
            <Box>
              <Box>
                <Paper
                  elevation={6}
                  sx={{
                    backgroundColor: "#000",
                    backdropFilter: "blur(5px) brightness-60",
                    padding: 5,
                    border: "solif 1px #fff",
                  }}
                >
                  <Box>
                    <Box
                      display={"flex"}
                      alignContent={"center"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <Box
                        component={"img"}
                        src={logo}
                        alt="Logo"
                        sx={{ width: "15%" }}
                      />
                    </Box>
                    <Box py={3} pl={1}>
                      <Typography
                        variant="h3"
                        fontFamily={"monospace"}
                        fontWeight={"Bold"}
                        color={"gold"}
                      >
                        Login to your account
                      </Typography>
                      <Box py={1}>
                        <Typography variant="h4">Let's get Started!</Typography>
                      </Box>
                    </Box>
                  </Box>
                  {/* Login Form */}
                  <Formik
                    validationSchema={schema}
                    initialValues={{ email: "", password: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                      }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,

                      isSubmitting,
                    }) => (
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        action="http://localhost:5000/api/auth/login"
                        method="POST"
                      >
                        {/* Our input html with passing formik parameters like handleChange,Values,Errors etc...*/}
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                          <Box>
                            <Input
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              placeholder="Hello@email.com"
                              label="Email"
                              id="email"
                              helpertext="ex: admin@ekf.com"
                            />
                            {/* if validation is not passed show errors */}
                            {errors.email && touched.email ? (
                              <div className="error">*{errors.email}</div>
                            ) : null}
                          </Box>
                          <Box>
                            <Input
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              placeholder="Type your password"
                              helpertext="ex: 12345678"
                              label="Passwords"
                            />
                            {/* if validation is not passed show errors */}
                            {errors.password && touched.password ? (
                              <Box sx={{}}>*{errors.password}</Box>
                            ) : null}
                          </Box>
                          <Box>
                            <SharedBTN
                              bgBtnHover=""
                              bgBtn="goldenrod"
                              title="Login"
                              type="submit"
                              width="100%"
                              height="100%"
                            />
                          </Box>
                        </Box>
                      </form>
                    )}
                  </Formik>
                  <Box paddingTop={1}>
                    <Typography>
                      don't have an account?
                      <NavLink
                        style={{
                          textDecoration: "underline",
                          color: "#fff",
                          paddingLeft: 5,
                        }}
                        to={"/"}
                      >
                        Sign up
                      </NavLink>
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Login;
