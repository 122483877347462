import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import PostForm from "../../components/Shared/Form/WholeSaleForm/PostForm";
import ProductForm from "../../components/Shared/Form/WholeSaleForm/ProductForm";
import { useDispatch } from "react-redux";
import { getPosts } from "../../actions/posts";
import { getProducts } from "../../actions/products";
import FormikForm from "../../components/Shared/Form/FormikForm";
import AddPost from "./AddPost";

const Dashboard = () => {
  const [postCurrentId, setPostCurrentId] = useState(0);
  const [productCurrentId, setProductCurrentId] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPosts());
  }, [postCurrentId, dispatch]);

  useEffect(() => {
    dispatch(getProducts());
  }, [productCurrentId, dispatch]);
  return (
    <>
      <AddPost currentId={postCurrentId} setCurrentId={setPostCurrentId} />
    </>
  );
};

export default Dashboard;
