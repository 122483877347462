import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Line from "../../assets/sustinability/want_to_do.png";
import farmer from "../../assets/sustinability/farmer.png";
import newtext from "../../assets/sustinability/new.png";
import helpers from "../../assets/sustinability/helpers.png";
import students from "../../assets/sustinability/students.png";
import place from "../../assets/sustinability/place.png";
import kids from "../../assets/sustinability/kids.png";
import kidsStudent from "../../assets/sustinability/kid_students.png";
import emiliofarmer from "../../assets/sustinability/emilio_farm.png";
import team from "../../assets/sustinability/team.png";
import title from "../../assets/sustinability/title.png";
import titlexs from "../../assets/sustinability/titlexs.png";

const Sustinability = () => {
  return (
    <div>
      <Box sx={{ paddingTop: "10%" }}>
        <Box sx={{ textAlign: "left", paddingBottom: "10%" }}>
          <Box
            component={"img"}
            src={titlexs}
            sx={{
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              display: {
                xl: "none",
                lg: "none",
                md: "flex",
                smL: "flex",
                xs: "flex",
              },
            }}
          />
          <Box
            component={"img"}
            src={title}
            sx={{
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              display: {
                xl: "flex",
                lg: "flex",
                md: "none",
                smL: "none",
                xs: "none",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            justifyContent: "space-around",
          }}
        >
          <Box>
            <Box
              sx={{
                paddingLeft: { xl: "10%", lg: "", md: "", sm: "", xs: "3%" },
                paddingRight: {
                  xl: "50%",
                  lg: "50%",
                  md: "50%",
                  sm: "50%",
                  xs: "0%",
                },
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xl: "30px",
                    lg: "30px",
                    md: "30px",
                    sm: "30px",
                    xs: "22px",
                  },
                  paddingBottom: "20px",
                  fontFamily: "Grotesk-Regular",
                }}
              >
                At Eleva, we work to uplift our coffee growing communities and
                help raise their standard of living all while heightening our
                coffee drinkers experience.
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xl: "30px",
                    lg: "30px",
                    md: "30px",
                    sm: "30px",
                    xs: "22px",
                  },
                  paddingBottom: "20px",
                  fontFamily: "Grotesk-Regular",
                }}
              >
                Since Eleva was founded in 2018, we have conceived of,
                orchestrated, and executed four major infrastructure projects
                within our origin communities, bringing in partner organizations
                to broaden the base of support for our farmers.
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xl: "30px",
                    lg: "30px",
                    md: "30px",
                    sm: "30px",
                    xs: "22px",
                  },
                  fontFamily: "Grotesk-Regular",
                }}
              >
                These projects brought over $60,000 in investments in these
                communities, plus an opportunity for strengthen the bond with
                our amazing coffee farmers.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: {
              xl: "-15%",
              lg: "10%",
              md: "10%",
              sm: "10%",
              xs: "10%",
            },
            marginRight: {
              xl: "10%",
              lg: "10%",
              md: "10%",
              sm: "10%",
              xs: "0%",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Box
              component={"img"}
              src={Line}
              sx={{
                width: {
                  xl: "70%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "-13%",
              paddingBottom: "0%",
              paddingRight: "2%",
            }}
          >
            <Box
              component={"img"}
              src={farmer}
              sx={{
                width: {
                  xl: "50%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
            />
          </Box>
        </Box>
        <Container maxWidth="xl" sx={{ marginTop: { xs: "10%" } }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    xl: "45px",
                    lg: "45px",
                    md: "45px",
                    sm: "35px",
                    xs: "35px",
                  },
                  fontFamily: "Grotesk-Regular",
                }}
              >
                Project 1
              </Typography>
            </Box>
            <Box
              sx={{
                paddingRight: { xl: "50%", sm: "" },
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xl: "50px",
                    lg: "45px",
                    md: "45px",
                    sm: "35px",
                    xs: "25px",
                  },
                  lineHeight: { xl: "3.2rem", lg: "", md: "", sm: "", xs: "" },
                  color: "#dec87c",
                  fontFamily: "Gallient",
                }}
              >
                Build new bio-degassing latrines and a self-sustaining kitchen
                in Peñas Blancas & Nicaragua
              </Typography>
            </Box>
          </Box>
        </Container>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box
            component={"img"}
            src={newtext}
            sx={{
              width: {
                xl: "50%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "125%",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: { xl: "-30%" },
            paddingLeft: { xl: "10%" },
            paddingBottom: "8%",
          }}
        >
          <Box
            component={"img"}
            src={helpers}
            sx={{
              width: {
                xl: "50%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
            }}
          />
        </Box>
        <Box sx={{ paddingX: "5%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    xl: "45px",
                    lg: "45px",
                    md: "45px",
                    sm: "35px",
                    xs: "35px",
                  },
                  fontFamily: "Grotesk-Regular",
                }}
              >
                Project 2
              </Typography>
            </Box>
            <Box
              sx={{
                paddingRight: { xl: "50%", sm: "" },
                textAlign: "left",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xl: "50px",
                    lg: "45px",
                    md: "45px",
                    sm: "35px",
                    xs: "25px",
                  },
                  lineHeight: { xl: "3.2rem", lg: "", md: "", sm: "", xs: "" },
                  color: "#dec87c",
                  fontFamily: "Gallient",
                }}
              >
                Refurbish two Elementary Schools in Santa Palencia, Guatemala
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                justifyContent: "space-around",
                flexWrap: {
                  xl: "nowrap",
                  lg: "nowrap",
                  md: "wrap",
                  sm: "wrap",
                  xs: "wrap",
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    paddingRight: "0%",
                    textAlign: "left",
                    paddingTop: "5%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "30px",
                        xs: "22px",
                      },
                      paddingBottom: "20px",
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    We refurbished two schools in the coffee farming community
                    of Santa Palencia, Guatemala. We teamed up with a group of
                    12 MBA candidates from University of Texas McCombs School of
                    Business who raised the funds to add a new roof and windows,
                    reinforce the foundations in order to weatherproof the
                    building, and repaint both schools, while at the same time
                    getting to know the residents who would be benefitting.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "30px",
                        xs: "22px",
                      },
                      paddingBottom: "20px",
                      color: "#dec87c",
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    300 schoolchildren and dozens of families were impacted by
                    this project at the Peñas Blancas Elementary School.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ paddingLeft: { xl: "10%" } }}>
                <Box
                  component={"img"}
                  src={students}
                  sx={{
                    display: {
                      xl: "flex",
                      lg: "flex",
                      md: "none",
                      sm: "none",
                      xs: "none",
                    },
                  }}
                />
                <Box
                  component={"img"}
                  src={students}
                  sx={{
                    display: {
                      xl: "none",
                      lg: "none",
                      md: "flexs",
                      sm: "flex",
                      xs: "flex",
                    },
                    width: { md: "100%", sm: "100%", xs: "100%" },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ paddingTop: "10%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                justifyContent: "space-around",
                flexWrap: {
                  xl: "nowrap",
                  lg: "nowrap",
                  md: "wrap",
                  sm: "wrap",
                  xs: "wrap",
                },
              }}
            >
              <Box>
                <Box
                  component={"img"}
                  src={place}
                  sx={{
                    display: {
                      xl: "flex",
                      lg: "flex",
                      md: "none",
                      sm: "none",
                      xs: "none",
                    },
                  }}
                />
              </Box>

              <Box sx={{ paddingRight: { xl: "10%" } }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "45px",
                        lg: "45px",
                        md: "45px",
                        sm: "35px",
                        xs: "35px",
                      },
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    Project 3
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingRight: { xl: "0%", sm: "" },
                    textAlign: "left",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "50px",
                        lg: "45px",
                        md: "45px",
                        sm: "35px",
                        xs: "25px",
                      },
                      lineHeight: {
                        xl: "3.2rem",
                        lg: "",
                        md: "",
                        sm: "",
                        xs: "",
                      },
                      color: "#dec87c",
                      fontFamily: "Gallient",
                    }}
                  >
                    Construct a new pre-school in Peñas Blancas, Nicaragua
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: "left",
                    paddingTop: "5%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "30px",
                        xs: "22px",
                      },
                      paddingBottom: "20px",
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    Eleva partnered with Seeds for Progress, Mercon Coffee and
                    Project Alianza to build a much safer, more modern building
                    to house the pre-school, so that Peñas Blancas’s youngest
                    students can start their education off
                    comfortably and safely.
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  component={"img"}
                  src={place}
                  sx={{
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    display: {
                      xl: "none",
                      lg: "none",
                      md: "flex",
                      sm: "flex",
                      xs: "flex",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            component={"img"}
            src={kids}
            sx={{
              display: {
                xl: "flex",
                lg: "flex",
                md: "none",
                sm: "none",
                xs: "none",
                display: "flex",
                justifyContent: "flex-end",
              },
            }}
          />
          <Box
            component={"img"}
            src={kids}
            sx={{
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              display: {
                xl: "none",
                lg: "none",
                md: "flex",
                sm: "flex",
                xs: "flex",
              },
            }}
          />
        </Box>
        <Box sx={{ paddingTop: "10%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                justifyContent: "space-around",
                flexWrap: {
                  xl: "nowrap",
                  lg: "nowrap",
                  md: "wrap",
                  sm: "wrap",
                  xs: "wrap",
                },
              }}
            >
              <Box sx={{ paddingRight: "0%", paddingLeft: "5%" }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "45px",
                        lg: "45px",
                        md: "45px",
                        sm: "35px",
                        xs: "35px",
                      },
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    Project 4
                  </Typography>
                </Box>
                <Box
                  sx={{
                    paddingRight: { xl: "0%", sm: "" },
                    textAlign: "left",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "50px",
                        lg: "45px",
                        md: "45px",
                        sm: "35px",
                        xs: "25px",
                      },
                      lineHeight: {
                        xl: "3.2rem",
                        lg: "",
                        md: "",
                        sm: "",
                        xs: "",
                      },
                      color: "#dec87c",
                      fontFamily: "Gallient",
                    }}
                  >
                    Refurbish two Elementary Schools in Santa
                    Palencia, Guatemala{" "}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    textAlign: "left",
                    paddingTop: "5%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "30px",
                        xs: "22px",
                      },
                      paddingBottom: "20px",
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    The first volunteer project was such a success that McCombs
                    selected Santa Palencia again for another student immersion
                    trip. This time, 8 students raised the funds to build a
                    much-needed new septic system and water tower for the local
                    elementary school.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "30px",
                        xs: "22px",
                      },
                      paddingBottom: "20px",
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    Once again, the students spent a week in the village working
                    on the improvements to the school. This year, Eleva added a
                    new element a day working on the coffee farm.
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Box
                  component={"img"}
                  src={kidsStudent}
                  sx={{
                    display: {
                      xl: "flex",
                      lg: "flex",
                      md: "none",
                      sm: "none",
                      xs: "none",
                    },
                  }}
                />
                <Box
                  component={"img"}
                  src={kidsStudent}
                  sx={{
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    display: {
                      xl: "none",
                      lg: "none",
                      md: "flex",
                      sm: "flex",
                      xs: "flex",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: {
                  xl: "nowrap",
                  lg: "nowrap",
                  md: "wrap",
                  sm: "wrap",
                  xs: "wrap",
                },
              }}
            >
              <Box sx={{ paddingLeft: { xl: "10%" } }}>
                <Box
                  component={"img"}
                  src={emiliofarmer}
                  sx={{
                    display: {
                      xl: "flex",
                      lg: "flex",
                      md: "none",
                      sm: "none",
                      xs: "none",
                    },
                  }}
                />
                <Box
                  component={"img"}
                  src={emiliofarmer}
                  sx={{
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    display: {
                      xl: "none",
                      slg: "none",
                      md: "flex",
                      sm: "flex",
                      xs: "flex",
                    },
                  }}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    textAlign: "left",
                    paddingTop: "5%",
                    paddingLeft: { xl: "25%", xs: "5%" },
                    paddingRight: { xl: "15%" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "30px",
                        xs: "22px",
                      },
                      paddingBottom: "20px",
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    The students and a team from Eleva spent a day working
                    alongside the coffee farmers, putting down organic
                    fertilizer, pruning trees, and planting new trees.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xl: "30px",
                        lg: "30px",
                        md: "30px",
                        sm: "30px",
                        xs: "22px",
                      },
                      paddingBottom: "20px",
                      fontFamily: "Grotesk-Regular",
                    }}
                  >
                    It was another memorable experience for both the students
                    and community of Santa Palencia. After two consecutive
                    years, a deep bond has been formed between the students and
                    the farmers, and the two elementary schools of Santa
                    Palencia have truly been transformed.
                  </Typography>
                </Box>
                <Box>
                  <Box
                    component={"img"}
                    src={team}
                    sx={{
                      display: {
                        xl: "flex",
                        lg: "flex",
                        md: "none",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                  />
                  <Box
                    component={"img"}
                    src={team}
                    sx={{
                      width: {
                        xl: "100%",
                        lg: "100%",
                        md: "100%",
                        sm: "100%",
                        xs: "100%",
                      },
                      display: {
                        xl: "none",
                        lg: "none",
                        md: "flex",
                        sm: "flex",
                        xs: "flex",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Sustinability;
